// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicKeyRow } from "./plasmic/byte_api_frontend/PlasmicKeyRow";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { validateAPIToken } from "../store/auth";

function KeyRow_(props, ref) {
  const dispatch = useDispatch();
  const clickHandler = (params) => {
    setChecked(!checked);
    dispatch(
      validateAPIToken({
        id: props.id,
        validity: !checked,
        mode: "modify",
      })
    );
  };
  const deleteHandler = (params) => {
    dispatch(
      validateAPIToken({
        id: props.id,
        validity: checked,
        mode: "delete",
      })
    );
  };

  const [checked, setChecked] = useState(props.isActive);
  return (
    <PlasmicKeyRow
      //onClick={clickHandler}
      root={{ ref }}
      active={{ isChecked: checked, onChange: () => clickHandler() }}
      confirmdelete={{
        confirm: { onClick: () => deleteHandler() },
      }}
      {...props}
    />
  );
}

const KeyRow = React.forwardRef(KeyRow_);

export default KeyRow;
