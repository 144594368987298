import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/index";
import Button from "./Button";
import "./ErrorModal.css";
import Modal from "./Modal";
import { Transition } from "react-transition-group";

const ErrorModal = (props) => {
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.modal.showModal);
  const closeHandler = (params) => {
    dispatch(modalActions.hide());
  };
  return (
    <Transition in={showModal} timeout={300}>
      {(state) => (
        <div>
          <div
            className={`backdrop ${
              state === "entered"
                ? "open"
                : state === "exiting"
                ? "closing"
                : ""
            }`}
            onClick={closeHandler}
          ></div>
          <div className="modal">{props.children}</div>
        </div>
      )}
    </Transition>
  );
};

export default ErrorModal;
