import React from "react";

const RedirectPage = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const next = queryParameters.get("next");
  React.useEffect(() => {
    window.location.replace("" + next);
  }, []);

  return <div></div>;
};

export default RedirectPage;
