import { createSlice } from "@reduxjs/toolkit";

const initialModalState = { modalHeader: "", modalBody: "", showModal: false };

const modalSlice = createSlice({
  name: "modal",
  initialState: initialModalState,
  reducers: {
    show(state) {
      state.showModal = true;
    },
    hide(state) {
      state.showModal = false;
    },
  },
});

export default modalSlice;
