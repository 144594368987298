// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicBuyTokens } from "./plasmic/byte_api_frontend/PlasmicBuyTokens";
import PayPalButtonComponent from "./PayPalButton2";
import { useState } from "react";

function BuyTokens_(props, ref) {
  const [selected1, setSelected1] = useState(true);
  const [selected2, setSelected2] = useState(false);
  const [selected3, setSelected3] = useState(false);

  const [paymentDetails, setPaymentDetails] = useState({
    pricelabel: "4,99€",
    amountlabel: "100 Credits",
    articlenumber: "P00100",
  });

  const select100 = (amount) => {
    setSelected1(true);
    setSelected2(false);
    setSelected3(false);
    setPaymentDetails({
      pricelabel: "4,99€",
      amountlabel: "100 Credits",
      articlenumber: "P00100",
    });
  };
  const select500 = (amount) => {
    setSelected1(false);
    setSelected2(true);
    setSelected3(false);
    setPaymentDetails({
      pricelabel: "9,99€",
      amountlabel: "500 Credits",
      articlenumber: "P00500",
    });
  };
  const select1000 = (amount) => {
    setSelected1(false);
    setSelected2(false);
    setSelected3(true);
    setPaymentDetails({
      pricelabel: "15,99€",
      amountlabel: "1000 Credits",
      articlenumber: "P01000",
    });
  };

  return (
    <PlasmicBuyTokens
      buttonsection100={{
        children: (
          <PayPalButtonComponent articlenumber={paymentDetails.articlenumber} />
        ),
      }}
      item100={{
        onClick: (params) => select100(100),
        selected: selected1,
      }}
      item500={{
        onClick: (params) => select500(500),
        selected: selected2,
      }}
      item1000={{
        onClick: (params) => select1000(1000),
        selected: selected3,
      }}
      creditamount={{
        children: paymentDetails.amountlabel,
      }}
      creditprice={{
        children: paymentDetails.pricelabel,
      }}
      totalcreditprice={{
        children: paymentDetails.pricelabel,
      }}
      root={{ ref }}
      {...props}
    />
  );
}

const BuyTokens = React.forwardRef(BuyTokens_);

export default BuyTokens;
