import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import Spinner from "./Spinner";
import LoadingPage from "../components/LoadingPage";

const ProtectedRoutes = (props) => {
  //checks if a user is authenticated by sending a GET request to an API endpoint and renders the child routes if authenticated, otherwise it redirects the user to the login page.

  //Initialize state variables.
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const isAuth = async () => {
    //The isAuth function checks if a user is authenticated by sending a GET request to an API endpoint with the necessary headers and parameters.
    //If the request is successful, the response data is stored in the response state variable and the loading variable is set to false.
    //If the request fails, the response variable is set to false, and the loading variable is set to false.
    if (localStorage.getItem("token")) {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/utils/users/validateauth/`,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {},
          }
        );
        setUserAuthenticated(res.data);
        setLoading(false);
      } catch (err) {
        setUserAuthenticated(false);
        setLoading(false);
      }
    } else {
      setUserAuthenticated(false);
      setLoading(false);
    }
  };

  // The useEffect hook is used to trigger the isAuth function when the component mounts. Since it has an empty dependency array, it only runs once.
  useEffect(() => {
    isAuth();
  }, []);

  //The renderComponent variable is defined to store either the child routes or a Navigate component that redirects the user to the login page if they are not authenticated.
  const renderComponent = userAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login?next=/profile" />
  );
  //The output variable is set to "loadingspinner" if the loading state variable is true or to the renderComponent variable if the response state variable is true.
  const output = loading ? (
    <LoadingPage label="Loading Profile" />
  ) : (
    renderComponent
  );

  return output;
};

export default ProtectedRoutes;
