import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth";
import notesSlice from "./notes";
import modalSlice from "./modal";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    notes: notesSlice.reducer,
    modal: modalSlice.reducer,
  },
});

export const authActions = authSlice.actions;
export const notesActions = notesSlice.actions;
export const modalActions = modalSlice.actions;

export default store;
