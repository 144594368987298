import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let token = "";
let user = {};
let isAuthenticated = false;

if (localStorage.getItem("token") && JSON.parse(localStorage.getItem("user"))) {
  token = localStorage.getItem("token");
  user = JSON.parse(localStorage.getItem("user"));
  isAuthenticated = true;
}

const initalAuthSlice = {
  isLoading: false,
  createUser: {
    usernameError: "",
    passwordError: "",
    isSubmitted: "",
  },
  userData: {
    isAuthenticated: isAuthenticated,
    user: user,
    token: token,
    userName: "",
    userEmail: "",
  },
};

export const createUser = createAsyncThunk(
  "user/signup",
  async (input, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/utils/users/signup/`,
        input.data,
        {
          "Content-type": "application/json",
        }
      );
      if (input.redirect) {
        input.history.push(input.redirect);
      }
      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

export const createAPIToken = createAsyncThunk(
  "user/createAPIToken",
  async (input, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/utils/createkey/`,
        {
          name: input.name,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (input.redirect) {
        input.history.push(input.redirect);
      }
      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

export const validateAPIToken = createAsyncThunk(
  "user/validateAPIToken",
  async (input, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/utils/validatekey/`,
        {
          id: input.id,
          validity: input.validity,
          mode: input.mode,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (input.redirect) {
        input.history.push(input.redirect);
      }
      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/login",
  async (input, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/utils/users/login/`,
        input.data,
        {
          "Content-type": "application/json",
        }
      );

      if (input.redirect) {
        input.navigate(input.redirect);
      }

      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "user/data",
  async (input, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/utils/users/profile/`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "user/logout",
  async (input, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/utils/users/logout/`,
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (input.redirect) {
        input.navigate(input.redirect);
      }
      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: initalAuthSlice,
  reducers: {
    setAuthFromStorage(state) {
      if (!state.userData.token) {
        if (localStorage.getItem("token")) {
          state.userData.token = localStorage.getItem("token");
          state.userData.isAuthenticated = true;
        }
        if (JSON.parse(localStorage.getItem("user"))) {
          state.userData.user = JSON.parse(localStorage.getItem("user"));
        } else {
          state.userData.token = "";
          state.userData.isAuthenticated = false;
        }
      }
    },
  },

  extraReducers: {
    [createUser.pending]: (state, action) => {
      state.isLoading = true;
      state.createUser.isSubmitted = true;
    },
    [createUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.createUser.isSubmitted = false;
      state.userData.user = action.payload;
    },
    [createUser.rejected]: (state, action) => {
      try {
        for (const [key, value] of Object.entries(action.payload)) {
          console.log(`${key}: ${value}`);
        }
      } catch {
        console.log("There has been an error");
      }
    },
    [createAPIToken.pending]: (state, action) => {},
    [createAPIToken.fulfilled]: (state, action) => {
      state.createUser.isSubmitted = false;
      state.userData.user.api_keys = action.payload;
      localStorage.setItem("user", JSON.stringify(state.userData.user));
    },
    [createAPIToken.rejected]: (state, action) => {
      try {
        for (const [key, value] of Object.entries(action.payload)) {
          console.log(`${key}: ${value}`);
        }
      } catch {
        console.log("There has been an error");
      }
    },
    [validateAPIToken.pending]: (state, action) => {},
    [validateAPIToken.fulfilled]: (state, action) => {
      state.createUser.isSubmitted = false;
      state.userData.user.api_keys = action.payload;
      localStorage.setItem("user", JSON.stringify(state.userData.user));
    },
    [validateAPIToken.rejected]: (state, action) => {
      console.log(action.payload);
    },
    [loginUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userData.isAuthenticated = true;
      state.userData.token = action.payload.access;
      state.userData.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem("token", action.payload.access);
    },
    [loginUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.userData.isAuthenticated = false;
      try {
        for (const [key, value] of Object.entries(action.payload)) {
          console.log(`${key}: ${value}`);
        }
      } catch {
        console.log("There has been an error");
      }
    },

    [getCurrentUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.userData.user.tokens = action.payload.credits;
      state.userData.user.api_keys = action.payload.api_keys;
      state.isLoading = false;

      //localStorage.setItem("user", JSON.stringify(action.payload));
    },
    [getCurrentUser.rejected]: (state, action) => {
      state.isLoading = false;
      try {
        for (const [key, value] of Object.entries(action.payload)) {
          console.log(`${key}: ${value}`);
        }
      } catch {
        console.log("There has been an error");
      }
    },

    [logoutUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [logoutUser.fulfilled]: (state, action) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      state.isLoading = false;
      state.userData.user = "";
      state.userData.isAuthenticated = false;
      state.userData.token = "";
    },
    [logoutUser.rejected]: (state, action) => {
      state.isLoading = false;
      try {
        for (const [key, value] of Object.entries(action.payload)) {
          console.log(`${key}: ${value}`);
        }
      } catch {
        console.log("There has been an error");
      }
    },
  },
});
export default authSlice;
