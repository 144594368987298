import {
  makeStyles,
  shorthands,
  Spinner,
  tokens,
  FluentProvider,
  webLightTheme,
} from "@fluentui/react-components";

import React from "react";

const useStyles = makeStyles({
  container: {
    "> div": { ...shorthands.padding("20px") },
  },

  // Inverted Spinners are meant as overlays (e.g., over an image or similar)
  // so give it a solid, dark background so it is visible in all themes.
  invertedWrapper: {
    backgroundColor: tokens.colorBrandBackgroundStatic,
  },
});

const Spinner_ = (props) => {
  const styles = useStyles();
  const label = props.label ? props.label : "Loading...";
  return (
    <FluentProvider theme={webLightTheme}>
      <div>
        <div className={styles.container}>
          <Spinner appearance="primary" label={label} />
        </div>
      </div>
    </FluentProvider>
  );
};

export default Spinner_;
