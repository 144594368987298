import React from "react";
import Button from "./Button";

function PayPalButtonComponent(props) {
  const createOrder = (params) => {
    const articlenumber = props.articlenumber;
    console.log(articlenumber);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/paypal/create/order?articlenumber=${props.articlenumber}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) =>
        window.location.replace(result.substring(1, result.length - 1))
      )
      .catch((error) => console.log("error", error));
  };

  return <Button onClick={createOrder}>Checkout with Paypal</Button>;
}

export default PayPalButtonComponent;
