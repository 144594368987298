// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function LoginSvgrepoComsvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 499.1 499.1"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M0 249.6c0 9.5 7.7 17.2 17.2 17.2h327.6l-63.9 63.8c-6.7 6.7-6.7 17.6 0 24.3 3.3 3.3 7.7 5 12.1 5s8.8-1.7 12.1-5l93.1-93.1c6.7-6.7 6.7-17.6 0-24.3l-93.1-93.1c-6.7-6.7-17.6-6.7-24.3 0-6.7 6.7-6.7 17.6 0 24.3l63.8 63.8H17.2c-9.5 0-17.2 7.6-17.2 17.1z"
        }
      ></path>

      <path
        d={
          "M396.4 494.2c56.7 0 102.7-46.1 102.7-102.8V107.7C499.1 51 453 4.9 396.4 4.9H112.7C56 4.9 10 51 10 107.7V166c0 9.5 7.7 17.1 17.1 17.1 9.5 0 17.2-7.7 17.2-17.1v-58.3c0-37.7 30.7-68.5 68.4-68.5h283.7c37.7 0 68.4 30.7 68.4 68.5v283.7c0 37.7-30.7 68.5-68.4 68.5H112.7c-37.7 0-68.4-30.7-68.4-68.5v-57.6c0-9.5-7.7-17.2-17.2-17.2S10 324.3 10 333.8v57.6c0 56.7 46.1 102.8 102.7 102.8h283.7z"
        }
      ></path>
    </svg>
  );
}

export default LoginSvgrepoComsvgIcon;
/* prettier-ignore-end */
