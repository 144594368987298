import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const inititialNotesSlice = { isLoading: false, notesList: [], note: {} };

export const getAllNotes = createAsyncThunk(
  "notes/getall",
  async (input, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/v1/notes/", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${input.apitoken}`,
        },
      });

      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

export const addNote = createAsyncThunk(
  "notes/add",
  async (input, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post("/api/v1/notes/", input.data, {
        headers: {
          Authorization: `Token ${input.apitoken}`,
        },
      });
      if (input.redirect) {
        input.history.push(input.redirect);
      }

      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

export const deleteNote = createAsyncThunk(
  "notes/delete",
  async (input, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/api/v1/notes/${input.id}/`,

        {
          headers: {
            Authorization: `Token ${input.apitoken}`,
          },
        }
      );
      if (input.redirect) {
        input.history.push(input.redirect);
      }
      return input.id;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

export const updateNote = createAsyncThunk(
  "notes/update",
  async (input, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `/api/v1/notes/${input.id}/`,
        input.data,
        {
          headers: {
            Authorization: `Token ${input.apitoken}`,
          },
        }
      );
      if (input.redirect) {
        input.history.push(input.redirect);
      }
      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

const notesSlice = createSlice({
  name: "notes",
  initialState: inititialNotesSlice,
  reducers: {},
  extraReducers: {
    [getAllNotes.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllNotes.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notesList = action.payload;
    },
    [getAllNotes.rejected]: (state, action) => {
      state.isLoading = false;

      try {
        for (const [key, value] of Object.entries(action.payload)) {
          console.log(`${key}: ${value}`);
        }
      } catch {
        console.log("There has been an error");
      }
    },
    [addNote.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addNote.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notesList = [...state.notesList, action.payload];
    },
    [addNote.rejected]: (state, action) => {
      state.isLoading = false;

      try {
        for (const [key, value] of Object.entries(action.payload)) {
          console.log(`${key}: ${value}`);
        }
      } catch {
        console.log("There has been an error");
      }
    },
    [deleteNote.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteNote.fulfilled]: (state, action) => {
      state.isLoading = false;

      const newList = state.notesList.filter(
        (item) => item.id !== action.payload
      );

      state.notesList = newList;
    },
    [deleteNote.rejected]: (state, action) => {
      state.isLoading = false;

      try {
        for (const [key, value] of Object.entries(action.payload)) {
          console.log(`${key}: ${value}`);
        }
      } catch {
        console.log("There has been an error");
      }
    },
    [updateNote.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateNote.fulfilled]: (state, action) => {
      state.isLoading = false;

      const updatedNotes = state.notesList.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });

      state.notesList = updatedNotes;
    },
    [updateNote.rejected]: (state, action) => {
      state.isLoading = false;

      try {
        for (const [key, value] of Object.entries(action.payload)) {
          console.log(`${key}: ${value}`);
        }
      } catch {
        console.log("There has been an error");
      }
    },
  },
});

export default notesSlice;
