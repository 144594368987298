// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicLoginPage } from "./plasmic/byte_api_frontend/PlasmicLoginPage";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../store/auth";

import { useNavigate } from "react-router-dom";

function LoginPage_(props, ref) {
  const dispatch = useDispatch();

  const queryParameters = new URLSearchParams(window.location.search);
  const next = queryParameters.get("next");

  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const onPasswordChange = (e) => {
    setState({ password: e.target.value, username: state.username });
  };

  const onUsernameChange = (e) => {
    setState({ username: e.target.value, password: state.password });
  };
  const navigate = useNavigate();
  const onSignupClick = async () => {
    const userData = {
      username: state.username,
      password: state.password,
    };

    dispatch(
      loginUser({
        data: userData,
        redirect: next ? next : "/profile/",
        navigate: navigate,
      })
    );
  };

  return (
    <PlasmicLoginPage
      usernameInput={{
        value: state.username,
        onChange: (params) => onUsernameChange(params),
      }}
      passwordInput={{
        value: state.password,
        type: "password",
        onChange: (params) => onPasswordChange(params),
      }}
      loginbutton={{
        onClick: (params) => onSignupClick(params),
      }}
      root={{ ref }}
      {...props}
    />
  );
}

const LoginPage = React.forwardRef(LoginPage_);

export default LoginPage;
