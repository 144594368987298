// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicHeader } from "./plasmic/byte_api_frontend/PlasmicHeader";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../store/auth";

function Header_(props, ref) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const docsHandler = (params) => {
    navigate("/docs/");
  };
  const auth = useSelector((state) => state.auth);
  const loginHandler = (params) => {
    navigate("/login/");
  };
  const logoutHandler = (params) => {
    dispatch(
      logoutUser({
        redirect: "/",
        navigate: navigate,
      })
    );
  };

  return (
    <PlasmicHeader
      docs={{ onClick: (params) => docsHandler() }}
      signin={{
        onClick: (params) => loginHandler(),
        style: { display: !auth.userData.isAuthenticated ? undefined : "none" },
      }}
      pricing={{
        onClick: (params) => navigate("/pricing/"),
        style: { display: !auth.userData.isAuthenticated ? undefined : "none" },
      }}
      signup={{
        onClick: (params) => navigate("/signup/"),
        style: { display: !auth.userData.isAuthenticated ? undefined : "none" },
      }}
      logout={{
        onClick: (params) => logoutHandler(),
        style: { display: auth.userData.isAuthenticated ? undefined : "none" },
      }}
      profile={{
        onClick: (params) => navigate("/profile/"),
        style: { display: auth.userData.isAuthenticated ? undefined : "none" },
      }}
      root={{ ref }}
      {...props}
    />
  );
}

const Header = React.forwardRef(Header_);

export default Header;
