// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PlasmicProfile } from "./plasmic/byte_api_frontend/PlasmicProfile";
import { useNavigate } from "react-router-dom";
import KeyRow from "./KeyRow";

import { getCurrentUser } from "../store/auth";
import LoadingPage from "./LoadingPage";

function Profile_(props, ref) {
  const [showTokens, setShowTokens] = useState(false);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buyCredits = (params) => {
    navigate("/buy-tokens?");
  };

  React.useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const output = auth.isLoading ? (
    <LoadingPage label="Loading Profile" />
  ) : (
    <PlasmicProfile
      //buycredits={{
      //  onClick: (params) => dispatch(modalActions.show()),
      //}}

      buycredits={{
        onClick: (params) => buyCredits(),
      }}
      keyrowscontainer={{
        children: [
          auth.userData.user.api_keys.map((key) => {
            return (
              <KeyRow
                id={key.id}
                name={key.name}
                apikey={key.key}
                isActive={key.is_active}
                key={key.id}
              />
            );
          }),
        ],
      }}
      fullnamelabel={{
        children:
          auth.userData.user.user.first_name +
          " " +
          auth.userData.user.user.last_name,
      }}
      emaillabel={{
        children: auth.userData.user.user.username,
      }}
      planlabel={{
        children:
          "You are currently on the " + auth.userData.user.user.groups[0],
      }}
      tokenbalancelabel={{
        children:
          "You have " + auth.userData.user.tokens.total_tokens + " credits.",
      }}
      hidetokens={!showTokens}
      showtokenbutton={{
        onClick: (params) => setShowTokens(true),
        style: { display: !showTokens ? undefined : "none" },
      }}
      hidetokenbutton={{
        onClick: (params) => setShowTokens(false),
        style: { display: showTokens ? undefined : "none" },
      }}
      createkeybuttonkomponent={{
        style: { display: showTokens ? undefined : "none" },
      }}
      root={{ ref }}
      {...props}
    />
  );

  return output;
}

const Profile = React.forwardRef(Profile_);

export default Profile;
