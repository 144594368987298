// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicCreatekeybuttoncomponent } from "./plasmic/byte_api_frontend/PlasmicCreatekeybuttoncomponent";
import { createAPIToken } from "../store/auth";
import { useDispatch } from "react-redux";
import { useState } from "react";

function Createkeybuttoncomponent_(props, ref) {
  const dispatch = useDispatch();
  const [keyname, setKeyName] = useState("");
  const [inputError, setInputError] = useState(false);

  const createAPITokenHandler = (params) => {
    if (keyname.length > 0) {
      setInputError(false);
      dispatch(
        createAPIToken({
          name: keyname,
        })
      );
      setKeyName("");
    } else {
      setInputError(true);
    }
  };

  const onNameKeyChange = (e) => {
    if (inputError) {
      setInputError(false);
    }
    setKeyName(e.target.value);
  };

  return (
    <PlasmicCreatekeybuttoncomponent
      popup={{
        onBlur: (params) => setInputError(false),
      }}
      createapikeybutton={{
        onClick: (params) => createAPITokenHandler(),
      }}
      nameInput={{
        value: keyname,
        error: inputError,
        onChange: (params) => onNameKeyChange(params),
      }}
      {...props}
    />
  );
}

const Createkeybuttoncomponent = React.forwardRef(Createkeybuttoncomponent_);

export default Createkeybuttoncomponent;
