import Homepage from "./components/Homepage";
import SignUpPage from "./components/SignUpPage";
import LoginPage from "./components/LoginPage";
import Docs from "./components/Docs";
import Pricing from "./components/Pricing";
import Profile from "./components/Profile";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "./store";
import Modal from "./components/Modal";
import ErrorModal from "./components/ErrorModal";
import Construction from "./components/Construction";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./utils/ProtectedRoutes";

import Spinner from "./utils/Spinner";
import LoadingPage from "./components/LoadingPage";
import BuyTokens from "./components/BuyTokens";
import RedirectPage from "./components/RedirectPage";

function App() {
  const dispatch = useDispatch();
  //const [showModal, setShowModal] = useState(false);
  const showModal = useSelector((state) => state.modal.showModal);
  const auth = useSelector((state) => state.auth);
  const showModalHandler = (params) => {
    dispatch(modalActions.show());
  };

  {
    auth.isLoading ? (
      <LoadingPage />
    ) : (
      <div>
        {showModal && (
          <ErrorModal key={showModal}>
            <Modal />
          </ErrorModal>
        )}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Construction />} />
            <Route path="/login" element={<LoginPage />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/profile" element={<Profile />} />
            </Route>
            <Route element={<ProtectedRoutes />}>
              <Route path="/payment-redirect" element={<RedirectPage />} />
            </Route>
            <Route element={<ProtectedRoutes />}>
              <Route path="/buy-tokens" element={<BuyTokens />} />
            </Route>

            <Route path="/spinner" element={<LoadingPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <div>
        {showModal && (
          <ErrorModal key={showModal}>
            <Modal />
          </ErrorModal>
        )}

        <Routes>
          <Route path="/" element={<Construction />} />
          <Route path="/login" element={<LoginPage />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/payment-redirect" element={<RedirectPage />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/buy-tokens" element={<BuyTokens />} />
          </Route>

          <Route path="/spinner" element={<LoadingPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
